import { isEmpty, omit, pick } from 'lodash-es';
import dayjs from 'dayjs';
import { Ref } from 'vue';
import { storeToRefs } from 'pinia';
import {
  HotelSuggestion,
  HotelSearchParams,
  HotelSearchType,
  HotelPath,
  HotelDetailQuery,
  HotelSearchQuery,
} from '#types/hotel';
import {
  ref,
  computed,
  toRaw,
  watch,
  useI18n,
  useConfigStore,
  useHotelService,
  refDebounced,
  combineStrings,
  useTracker,
  useGeolocation,
  useNavigation,
  sanitizeHotelName,
  useAirpazCookie,
  useHotelSearchStore,
  useDevice,
  useRoute,
  hasNonLatin,
  normalizeSlug,
  onMounted,
  validateCheckInDate,
  validateCheckOutDate,
} from '#imports';

interface IEmits {
  (e: 'submit', data: HotelSearchParams): void;
}

type Options = {
  initialSearchParams: Ref<
    { destination?: Pick<HotelSuggestion, 'id' | 'name' | 'type' | 'city'> | undefined } | undefined
  >;
};

export const useHotelSearchForm = (emit: IEmits, options?: Options) => {
  const { initialSearchParams } = options ?? {};

  const geolocation = useGeolocation();
  const route = useRoute();
  const { searchHistory } = storeToRefs(useHotelSearchStore());
  const { t } = useI18n();
  const { navigate } = useNavigation();
  const { jitsuEvent } = useTracker();
  const { addSearchHistory } = useHotelSearchStore();

  const destination = ref<Pick<HotelSuggestion, 'id' | 'name' | 'type' | 'city'>>();
  const travelDates = ref<string[]>([
    dayjs().add(1, 'day').format('YYYY-MM-DD'),
    dayjs().add(2, 'day').format('YYYY-MM-DD'),
  ]);
  const travelers = ref<Pick<HotelSearchParams, 'adult' | 'childAges' | 'rooms'>>({
    adult: 1,
    childAges: [],
    rooms: 1,
  });
  const errors = ref<{ [key: string]: string }>({});
  const timeOnOpen = ref<string>('');
  const showBlockedGeolocationDialog = ref(false);

  const nightCounts = computed(() => {
    const nights = dayjs(travelDates.value[1]).diff(travelDates.value[0], 'day');

    return nights < 0 ? 0 : nights;
  });

  watch(
    [destination, travelDates],
    ([destination, travelDates]) => {
      if (destination) {
        delete errors.value.destination;
      }

      if (travelDates[0]) {
        delete errors.value.checkInDate;
      }

      if (travelDates[1]) {
        delete errors.value.checkOutDate;
      }
    },
    { deep: true },
  );

  function handleSubmit(onSubmit?: (data: HotelSearchParams) => void) {
    if (!destination.value) {
      errors.value.destination = t('search.nodestinationentered');
    }

    if (!travelDates.value[0]) {
      errors.value.checkInDate = t('search.rqdeparturedate');
    }

    if (!travelDates.value[1]) {
      errors.value.checkOutDate = t('search.rqreturndate');
    }

    if (!isEmpty(errors.value)) {
      return;
    }

    const data: HotelSearchParams = {
      id: destination.value!.id,
      type: destination.value!.type,
      destName: destination.value!.name,
      checkInDate: travelDates.value[0]!,
      checkOutDate: travelDates.value[1]!,
      adult: travelers.value.adult,
      childAges: toRaw(travelers.value.childAges),
      rooms: travelers.value.rooms,
    };

    onSubmit?.(data);
  }

  function trackHotelDestinationPicker(condition: 'open' | 'close') {
    if (condition === 'open') {
      timeOnOpen.value = dayjs().format('YYYY-MM-DD HH:mm:ss');
      jitsuEvent('user-click-field', {
        event_name: 'hotel-autocomplete-open',
        product: 'hotel',
        object_name: 'auto-complete-search',
        object_parameter: 'destination',
      });
    } else if (condition === 'close') {
      jitsuEvent('hotel-autocomplete-close', {
        product: 'hotel',
        object_name: 'autocomplete',
        object_paramater: JSON.stringify({
          autocomplete_result: `${destination.value?.id}, ${destination.value?.name}, ${destination.value?.type}, ${destination.value?.city}`,
          time_done: dayjs().diff(dayjs(timeOnOpen.value)) / 1000,
        }),
      });
    }
  }

  function onSubmit(data: HotelSearchParams) {
    const query: { id: string; d?: string; t: string; ci?: string; co?: string; ro: number; ad: number; ca?: string } =
      {
        id: data.id,
        t: data.type,
        ci: data.checkInDate,
        co: data.checkOutDate,
        ro: data.rooms,
        ad: data.adult,
      };

    if (data.id === 'geo') {
      if (geolocation.error.value?.PERMISSION_DENIED) {
        showBlockedGeolocationDialog.value = true;

        return;
      }

      const coords = geolocation.coords.value;

      query.t = query.id;
      query.id = `${coords.longitude},${coords.latitude}`;

      useAirpazCookie('usr_coords').value = query.id;

      addSearchHistory({
        ...data,
        id: `${coords.longitude},${coords.latitude}`,
        name: t('hotel.nearbyproperties'),
        district: '',
        city: '',
        region: '',
      });
    } else {
      const hotelDestination = destination.value as HotelSuggestion;
      const hotelData = { ...data };

      delete hotelData.destName;

      addSearchHistory({
        ...data,
        name: hotelDestination.name,
        district: hotelDestination.district!,
        city: hotelDestination.city!,
        region: hotelDestination.region!,
      });
    }

    if (data.destName) {
      query.d = data.destName;
    }

    if (data.childAges.length > 0) {
      query.ca = data.childAges.join(',');
    }

    emit('submit', data);

    jitsuEvent('user-click-button', {
      event_name: 'hotel-searchform-search',
      product: 'hotel',
      object_name: 'search-form',
      object_parameter: 'search',
      search_type: data.type,
      search_id: data.id,
      search_parameter: data.destName ?? destination.value!.name,
      checkin_date: data.checkInDate ?? dayjs().add(1, 'day').format('YYYY-MM-DD'),
      checkout_date: data.checkOutDate ?? dayjs().add(1, 'day').format('YYYY-MM-DD'),
      total_room: data.rooms,
      adult: data.adult,
      child: data.childAges.length,
      child_age: query.ca ?? data.childAges.join(','),
    });

    if (data.type === 'hotel') {
      navigate(
        {
          path: `/hotel/${sanitizeHotelName(data.destName ?? '')}.${data.id}`,
          query: omit(query, ['id', 'd']),
        },
        { externalAirpaz: true },
      );
    } else {
      navigate({ path: '/hotel/search', query }, { externalAirpaz: true });
    }
  }

  onMounted(() => {
    if (initialSearchParams?.value) {
      if (initialSearchParams.value.destination) {
        destination.value = initialSearchParams.value.destination;
      }
    } else if (!isEmpty(searchHistory.value)) {
      destination.value = pick(searchHistory.value[0], ['id', 'name', 'type', 'city']) as {
        id: string;
        name: string;
        type: HotelSearchType;
        city: string;
      };
    }

    const { co, ci, ro, ad, ca } = route.query as any;
    if (isSet(co) && isSet(ci)) {
      const newCheckIn = validateCheckInDate(ci as string);
      const newCheckOut = validateCheckOutDate(co as string, newCheckIn);
      travelDates.value = [newCheckIn, newCheckOut];
    }

    if (isSet(ro)) {
      travelers.value.rooms = ro;
    }

    if (isSet(ad)) {
      travelers.value.adult = ad;
    }

    if (isSet(ca)) {
      const childAges = ca.split(',');

      travelers.value.childAges = childAges;
    }
  });

  return {
    destination,
    travelDates,
    travelers,
    nightCounts,
    errors,
    timeOnOpen,
    showBlockedGeolocationDialog,
    handleSubmit,
    trackHotelDestinationPicker,
    onSubmit,
  };
};

export const useHotelDestinationPicker = (timeOnOpen?: Ref<string | undefined>) => {
  const { t, locale } = useI18n();
  const config = useConfigStore();
  const { jitsuEvent } = useTracker();
  const { searchHistory } = storeToRefs(useHotelSearchStore());
  const { deleteSearchHistory } = useHotelSearchStore();

  const keyword = ref('');
  const suggestionsMemo = ref<{ [keyword: string]: HotelSuggestion[] }>({});
  const isFetchingSuggestions = ref(true);
  const isDefaultSuggestion = ref(false);

  const iconMap = {
    city: 'city',
    destination: 'destination',
    district: 'map-marker-alt',
    region: 'map-marked-alt',
    airport: 'plane-departure',
    hotel: 'hotel',
    area: 'pennant',
    landmark: 'landmark',
  };

  const keywordDebounced = refDebounced(keyword, 500);

  const nearbyPropertyModel = {
    id: 'geo',
    uniqueId: 'geo',
    name: t('hotel.nearbyproperties'),
    type: 'geo' as HotelSearchType,
  };

  const formattedSuggestions = computed(
    () =>
      (suggestionsMemo.value[keywordDebounced.value?.trim()]?.map((suggestion) => ({
        ...suggestion,
        // @ts-expect-error
        icon: iconMap[suggestion.type] ?? iconMap.hotel,
        uniqueId: `${suggestion.type}_${suggestion.id}`,
      })) ?? []) as Array<HotelSuggestion & { icon: string; uniqueId: string }>,
  );

  watch(keywordDebounced, (newKeyword) => {
    fetchSuggestions(newKeyword);
  });

  const isKeywordSufficient = (keyword: string) => keyword.length > 1 || (hasNonLatin(keyword) && keyword.length > 0);

  const sanitize = (text: string) => {
    return text ? text.replace(/</g, '&lt;').replace(/>/g, '&gt;') : text;
  };
  const escapeRegExp = (str: string) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/\s/g, '|');
  };

  const getSuggestionSubtitle = (suggestion: Pick<HotelSuggestion, 'district' | 'city' | 'region'>) =>
    combineStrings([suggestion.district!, suggestion.city!, suggestion.region!]);

  async function fetchSuggestions(keyword: string) {
    keyword = keyword.trim();

    isDefaultSuggestion.value = !keyword;

    if (suggestionsMemo.value[keyword]) {
      return;
    }

    isFetchingSuggestions.value = true;

    try {
      const { result } = await useHotelService().getHotelSuggestions({
        query: keyword,
        lang: locale.value,
        country: config.country!,
      });

      suggestionsMemo.value[keyword] = result;

      // tracker

      !isDefaultSuggestion.value &&
        trackHotelDestinationSearchInput(
          result.map((suggestion) => `${suggestion.type}_${suggestion.id}`).join(','),
          result,
        );
    } catch (e) {
    } finally {
      isFetchingSuggestions.value = false;
    }
  }

  function highlightKeyword(text: string) {
    if (keyword.value.length === 0) {
      return text;
    }

    const escapedKeyword = sanitize(escapeRegExp(keyword.value));

    text = sanitize(text);

    const re = new RegExp(escapedKeyword, 'gi');

    return text.replace(re, `<span class="text-primary">$&</span>`);
  }

  function trackSelectHotel(params: { id: string; city?: string; type?: string } | undefined, index?: number) {
    if (params) {
      const { city, id } = params;
      const notSelectedSuggestion = formattedSuggestions.value.filter((suggestion) => suggestion.id !== id).join(',');
      const selectedSuggestion = formattedSuggestions.value.filter((suggestion) => suggestion.id === id).join(',');

      if (isDefaultSuggestion.value) {
        jitsuEvent('user-click-preference', {
          event_name: 'hotel-autocomplete-select-defaultlist',
          object_name: 'auto-complete-search',
          object_parameter: 'destination-defaultlist',
          selected_value: selectedSuggestion,
          product: 'hotel',
          search_type: params.type ?? '',
          city_id: city ?? '',
          id,
        });
      } else {
        jitsuEvent('user-click-preference', {
          event_name: 'hotel-autocomplete-select-autocompletelist',
          object_name: 'auto-complete-search',
          object_parameter: 'search-result',
          selected_value: selectedSuggestion,
          user_input: keyword.value,
          position: index ?? '',
          product: 'hotel',
          search_type: params.type ?? '',
          city_id: city ?? '',
          id,
        });
      }

      jitsuEvent('hotel-autocomplete-type-noselect', {
        product: 'hotel',
        user_input: keyword.value,
        time_done: timeOnOpen ? dayjs().diff(dayjs(timeOnOpen.value), 'second') : 0,
        any_result: isEmpty(notSelectedSuggestion) ? 0 : 1,
        autocomplete_result: notSelectedSuggestion,
      });
    }
  }

  function trackSelectHotelNearbyProperties() {
    jitsuEvent('user-click-preference', {
      event_name: 'hotel-autocomplete-select-nearbyproperties',
      product: 'hotel',
      object_name: 'auto-complete-search',
      object_parameter: 'nearby-properties',
    });
  }

  function trackHotelDestinationSearchInput(suggestionResult: string, suggestions: any) {
    jitsuEvent('system-fetch-autocomplete', {
      event_name: 'hotel-autocomplete-type',
      product: 'hotel',
      object_name: 'autocomplete-fetch',
      object_parameter: JSON.stringify({
        user_input: keyword.value,
        total_result: suggestions.length,
        time_done: timeOnOpen ? dayjs().diff(dayjs(timeOnOpen.value), 'second') : 0,
        any_result: isEmpty(suggestionResult) ? 0 : 1,
        autocomplete_result: suggestionResult,
      }),
    });
  }

  return {
    keyword,
    keywordDebounced,
    nearbyPropertyModel,
    formattedSuggestions,
    isFetchingSuggestions,
    hotelSearchHistory: searchHistory,
    getSuggestionSubtitle,
    highlightKeyword,
    fetchSuggestions,
    trackSelectHotel,
    trackSelectHotelNearbyProperties,
    deleteSearchHistory,
    isKeywordSufficient,
  };
};

export const useHotelPath = () => {
  const { navigationPath } = useNavigation();
  const { isCrawler } = useDevice();

  function getHotelPath(hotel: HotelPath, query?: HotelDetailQuery, isStaticPage = false) {
    const slugValue = hotel.slug
      ? normalizeSlug(hotel.slug)
      : hotel.enName
      ? sanitizeHotelName(hotel.enName)
      : sanitizeHotelName(hotel.name);

    const path = `/hotel/${slugValue}.${hotel.id}`;

    if (isStaticPage) {
      query = {
        ci: dayjs().add(7, 'day').format('YYYY-MM-DD'),
        co: dayjs().add(8, 'day').format('YYYY-MM-DD'),
        ro: 1,
        ad: 1,
        ...query,
      };
    }

    return navigationPath(
      isCrawler
        ? path
        : {
            path,
            ...(query ? { query } : {}),
          },
    );
  }

  function getHotelSearchPath(hotel: HotelPath, query?: HotelSearchQuery, isStaticPage = false) {
    if (isStaticPage) {
      query = {
        id: hotel.id,
        d: hotel.name,
        t: 'city',
        ci: dayjs().add(7, 'day').format('YYYY-MM-DD'),
        co: dayjs().add(8, 'day').format('YYYY-MM-DD'),
        ro: 1,
        ad: 1,
        ...query,
      };
    }

    return navigationPath({ path: '/hotel/search', query });
  }

  return { getHotelPath, getHotelSearchPath };
};
